@import "~bootstrap-scss/bootstrap";

.content {
    @include media-breakpoint-down(lg) {
        margin: 0 40px;
    }
    @include media-breakpoint-between(lg, xxl) {
        margin: 0 70px;
    }
    @include media-breakpoint-up(xxl) {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.intersection-space {
    margin-bottom: 30px;
}