.skill-list {
    white-space: nowrap;
    padding: 15px 0;
}

.skills i.lni {
    font-size: 60px;
    padding: 15px 30px;
    color: #969696;
}
.skills i.lni:hover {
    font-size: 60px;
    padding: 15px 30px;
    color: black;
}

.mysql {
    padding: 0px 30px 0 30px;
}
.mongodb {
    height: 60px;
    width: 60px;
}
.mongodb-container {
    display: inline-block;
    position: relative;
    bottom: 20px;
    padding: 0 30px 20px 30px;
}
svg:hover {
    fill: black;
}

::-webkit-scrollbar {
    height: 5px;              /* height of horizontal scrollbar ← You're missing this */
    width: 5px;               /* width of vertical scrollbar */
    /* background-color: #e5e5e5; */
}

::-webkit-scrollbar-thumb {
    /* background-color: #54ca95; */
    background-color: #eee;
}
#mysql-tooltip {
    position: relative;
    top: -20px;
}