.testimonial {
    font-family: "Poppins";
    width: 500px;
    height: 180px;
    margin: 30px
}
.tuser-pic {
    height: 80px;
    width: 80px;
    border-radius: 80px;
    position: absolute;
    margin-left: -30px;
    margin-top: -30px;
}
.tbox {
    border-radius: 8px;
    border: 2px solid #e5e5e5;
    padding: 30px 20px 20px 60px;
    height: 100%;
}
.testimonial-text {
    font-weight: 400;
    font-style: italic;
    color: rgb(102, 102, 102);
    font-size: 15px;
}
.tbox h5 {
    font-size: 12.6px;
    font-weight: 600;
}
.tuser-firm {
    font-size: 11.6px;
    font-weight: 400;
    color: rgb(187, 187, 187)
}
.quote-left {
    height: 25px;
    position: relative;
    fill: rgb(229, 229, 229);
    bottom: 70px;
    left: 20px
}
.quote-right {
    height: 60px;
    position: relative;
    fill: rgb(229, 229, 229);
    right: -460px;
    top: -40px;
    z-index: -1;
}