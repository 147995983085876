// @import "~bootstrap-scss/bootstrap";
@import "./../../../breakpoint_mixins.scss";
.left-column {
    width: 220px;
}
.right-column {
    min-width: 300px;
}
.pointer {
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background-color: #54ca95;
    display: inline-block;
    top: 8px;
    position:relative;
}
.year {
    font-family: "Poppins",Helvetica,sans-serif;
    padding: 5px 15px;
    border: 2px solid #54ca95;
    border-radius: 30px;
    font-size: 13px;
}
.school {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px !important;
}
.location {
    color: #969696;
    font-size: 14px;
    text-transform: none;
}
.course {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
}
.in {
    font-family: "Poppins",Helvetica,sans-serif;
    color: #969696;
}
.branch {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
}
.marks {
    font-family: "Poppins",Helvetica,sans-serif;
    color: #969696;
}
.highlights {
    font-family: "Poppins",Helvetica,sans-serif;
    font-size: 13px;
}
ul li{
    padding: 0
}

@include media-breakpoint-up(xl) {
    .pointer {
        right: -15px;
    }
    .right-column {
        border-left: 2px solid #54ca95;
    }
    .tp-left {
        display: none;
    }
}
@include media-breakpoint-down(xl) {
    .pointer {
        left: -12px;
    }
    .timeline-point {
        border-left: 2px solid #54ca95;
    }
    .tp-right {
        display: none;
    }
}