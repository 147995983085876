.title h1 {
    font-family: "Poppins",Helvetica,sans-serif;
    display: inline-block;
    position: relative;
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 30px;
    z-index: 1;
    padding-bottom: 7px;
}
.title h1::before {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    background-color: #f5f5f5;
    height: 2px;
    bottom: 0;
}
.title h1:after {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    background-color: #54ca95;
    height: 2px;
    bottom: 0;
}