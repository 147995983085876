.experience-item {
    font-family: "Poppins",Helvetica,sans-serif;
}
.company {
    font-weight: 600;
    font-size: 16px !important;
}
.location {
    color: #969696;
    font-size: 14px;
    text-transform: none;
}
.designation {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
}
