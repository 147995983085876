// @import "~bootstrap-scss/bootstrap";
@import "./../../../breakpoint_mixins.scss";

.school {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px !important;
}
.location {
    color: #969696;
    font-size: 14px;
    text-transform: none;
}
.course {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
}
.in {
    font-family: "Poppins",Helvetica,sans-serif;
    color: #969696;
}
.branch {
    font-family: "Poppins",Helvetica,sans-serif;
    font-weight: 600;
}
.marks {
    font-family: "Poppins",Helvetica,sans-serif;
    color: #969696;
}
.highlights {
    font-family: "Poppins",Helvetica,sans-serif;
    font-size: 13px;
}
ul li{
    padding: 0
}
