.navbar {
    font-family: "Poppins",Helvetica,sans-serif;
}
.name h1 {
    font-size: 20px;
    line-height: 44px;
    letter-spacing: 0.5px;
    display: inline;
}
.page-header {
    margin-bottom: 40px;
}
ul li {
    font-size: 14px;
    padding: 0 20px;
}
ul li .nav-link {
    color: #9d9d9d;
}
ul li .nav-link:hover {
    color: #333;
}
ul li .nav-link:focus {
    color: #333;
}
.toggler-button {
    border: none;
}
.toggler-button:focus {
    border: none;
}
@media (max-width: 991.98px) {
    .page-header {
        -webkit-box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
    }
}