.cbtn {
    font-family: "Poppins",Helvetica,sans-serif;
    font-size: 14.5px;
    padding: 10px 30px;
    border-radius: 2em;
    -webkit-box-shadow: 0 10px 10px -8px #d5d5d5;
    -moz-box-shadow: 0 10px 10px -8px #d5d5d5;
    box-shadow: 0 10px 10px -8px #d5d5d5;
}
.cbtn-primary {
    border: 2px solid #54ca95;
    background-color: white;
    color: #222;
}
.cbtn-primary:hover {
    background-color: #54ca95;
    color: white;
}
.cbtn-secondary {
    border: 2px solid #d5d5d5;
    background-color: white;
    color: #333;
}
.cbtn-secondary:hover {
    background-color: #d5d5d5;
}
