.contactme {
    font-family: "Poppins";
    font-size: 14px;
}
.contactme form {
    width: 100%;
    max-width: 700px;
}
.contactme-btn {
    width: 100px;
}
.error-msg {
    color: #d74e4e;
}
.form-item {
    width: 100%;
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #54ca95;
    box-shadow: 0 10px 10px -8px #d5d5d5;
}
.shadow {
    box-shadow: 0 10px 10px -8px #d5d5d5;
}