.aboutmetext h4 {
    font-size: 16px;
    color: #aaa;
    font-weight: 300;
    font-family: "Poppins",Helvetica,sans-serif;
}

.aboutmetext h1 {
    display: block;
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: "Poppins",Helvetica,sans-serif;
}
.aboutmetext-content {
    font-family: "Poppins",Helvetica,sans-serif;
    font-size: 14px;
    font-weight: 0;
    font-style: normal;
    line-height: 2em;
    color: #666;
    text-align: justify;
    text-justify: inter-word;
    display: inline !important;
    margin-bottom: 10px;
}
.role {
    transition: transform .5s linear;
}

.countries-travelled {
    margin-bottom: 20px;
}

.countries-travelled img {
    margin: 4px;
    border: 1px solid grey;
    border-radius: 4px;
    opacity: 0.6;
}

.countries-travelled img:hover {
    opacity: 1;
}

.learning-highlight {
    color: white !important;
    background-color: black !important;
    padding: 0 3px;
    display: inline;
}