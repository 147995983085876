@import "~bootstrap-scss/bootstrap";
.picture-container {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: calc(100% - 36px);
    background-color: #fff;
    border-radius: 1000px;
    border: 18px solid #fff;
    overflow: hidden;
    -webkit-box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
}
.picture-placeholder {
    background-image: url('./../../../../assets/dp_cr_sm.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: block;
    position: absolute;
    top: -15px;
    bottom: -15px;
    width: auto;
    left: -15px;
    right: -15px;
}

.picture {
    @media (max-width: 500px) {
        padding: 5% 12% !important;
    }
    @media (min-width: 500px) and (max-width: 650px) {
        padding: 5% 19% !important;
    }
    @media (min-width: 650px) and (max-width: 768px) {
        padding: 5% 23% !important;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding: 5% 3% !important;
    }
    @media (min-width: 992px) and (max-width: 1100px) {
        padding: 5% 1% !important;
    }
    @media (min-width: 1100px) and (max-width: 1400px) {
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left: 9% !important;
    }
    @media (min-width: 1400px) {
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left: 10% !important;
    }
}
