.footer {
    font-family: "Poppins", sans-serif;
    box-shadow: 0 -3px 25px 0 rgb(0 0 0 / 10%);
}
.footer a {
    text-decoration: none;
}
.footer .lni {
    font-size: 20px;
    margin:15px;
    color: #969696;
}
.footer .lni:hover {
    color: black;
}
.bmac {
    height: 30px;
    margin: 10px;
}
.policy-statments a {
    color: #969696;
    font-size: 11px;
    margin : 3px 0;
}
.policy-statments a:hover {
    color: black;
}
.heart {
    color: #d74e4e;
}
.credits {
    color: #969696;
    font-size: 11px;
    margin: 10px 0;
}